import { API } from "../../api-config";
import SecureLS from "secure-ls";

const ls =
  typeof window === "undefined" ? {} : new SecureLS({ encodingType: "aes" });

export const getAllShopOrders = async () => {
  const token = ls.get("token");
  const shop = ls.get("shop");

  if (shop) {
    return await fetch(`${API}/order/list/all/shop/${shop._id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        //console.log(err)
        throw err;
      });
  }
};

export const getAllShopOrdersPaginated = async ({ page = 1, limit = 10 }) => {
  const token = ls.get("token");
  const shop = ls.get("shop");

  if (shop) {
    return await fetch(
      `${API}/order/list/paginated/shop/${shop._id}?page=${page}&limit=${limit}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((response) => {
        return response;
      })
      .catch((err) => {
        //console.log(err)
        throw err;
      });
  }
};

export const updateOrderStatus = async (orderId, status) => {
  const token = ls.get("token");
  const user = ls.get("user");

  return await fetch(`${API}/order/${orderId}/status/${user._id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({ status: status, orderId: orderId }),
  })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      //console.log(err)
      return err;
    });
};

export const declineOrder = async (orderId, declineMessage) => {
  const token = ls.get("token");
  const user = ls.get("user");

  return await fetch(`${API}/order/${orderId}/status/${user._id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      status: "Rejected",
      orderId: orderId,
      statusMessage: declineMessage,
    }),
  })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      //console.log(err)
      return err;
    });
};

export const getOrder = async (id) => {
  const token = ls.get("token");

  return await fetch(`${API}/order/tid/${id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => {
      if (response.status !== 200) {
        throw Error("Unable to get decline order, please try again later");
      }
      return response.json();
    })
    .catch((err) => {
      throw err;
    });
};

export const getOrderById = async ({ orderId }) => {
  const token = ls.get("token");

  return await fetch(`${API}/order/${orderId}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => {
      if (response.status !== 200) {
        throw Error("Unable to get order details.");
      }
      return response.json();
    })
    .catch((err) => {
      throw err;
    });
};
