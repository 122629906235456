import React from "react";
import {
  AppBar,
  Divider,
  IconButton,
  Paper,
  ThemeProvider,
  Toolbar,
  Typography,
} from "@mui/material";
import LabeledValues from "../../labels/LabeledValues";
import ProductListItem from "./components/ProductListItem";
import { Close } from "@mui/icons-material";
import { navigate } from "gatsby";
import { useParams } from "@reach/router";
import CenteredProgress from "../../progress/centeredProgress";
import OngoingOrderButtonAction from "./components/OngoingOrderButtonAction";
import { SelectedTheme } from "../../../context/ThemeContext/ThemeContext";
import { getOrderById } from "../../../api/public/order";
import { TriggerOrderRefresh } from "../../../context/OrderPageContext/OrderPageContext";

const OrderDetailsPage = () => {
  const params = useParams();

  const [themeName, themeValues, toggleDarkTheme] =
    React.useContext(SelectedTheme);

  const [isLoading, setisLoading] = React.useState(true);

  const [order, setOrder] = React.useState(null);

  let whenDate = new Date(order?.when);

  const handleClose = () => {
    navigate(-1);
  };

  const handleGetOrder = async () => {
    try {
      console.log(params?.orderId);

      let orderData = await getOrderById({ orderId: params?.orderId });

      setOrder(orderData);

      setisLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  React.useEffect(() => {
    handleGetOrder();
  }, []);

  return (
    <>
      {isLoading && <CenteredProgress />}
      {!isLoading && (
        <Paper>
          <AppBar sx={{ position: "relative" }}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
              >
                <Close />
              </IconButton>
              <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                Order details
              </Typography>
            </Toolbar>
          </AppBar>
          <LabeledValues label="Transaction ID" value={order?.transaction_id} />
          <LabeledValues label="Order for" value={whenDate.toUTCString()} />
          <LabeledValues label="Name" value={order?.user?.name} />
          <LabeledValues label="Contact" value={order?.user?.phone} />
          <LabeledValues label="Delivery Address" value={order?.address} />
          <LabeledValues label="Payment" value={order?.paymentType} />
          <LabeledValues label="Notes" value={order?.deliveryNotes} />
          <Typography m={"0.5em"} fontWeight={"bold"} fontSize={20}>
            Products
          </Typography>
          <div>
            {order?.products?.map((product) => {
              return <ProductListItem {...product} />;
            })}
          </div>
          <Divider />
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              margin: "0.5em",
            }}
          >
            <div
              style={{
                fontSize: "20px",
                fontWeight: "bold",
              }}
            >
              Total
            </div>
            <div
              style={{
                fontSize: "20px",
                fontWeight: "bold",
              }}
            >
              {`P ${order.amount}.0`}
            </div>
          </div>
          <OngoingOrderButtonAction
            order={order}
            additionalActions={handleClose}
          />
        </Paper>
      )}
    </>
  );
};

export default OrderDetailsPage;
