import React, { useRef, useState, useEffect } from 'react'
import './index.css'

const Test = ({ text = 'Sample text here', onDrag = () => { } }) => {
	let [loc, setLoc] = useState(0)
	let [elementWidth, setElementWidth] = useState(0)
	let [stopped, setStopped] = useState(false)

	let ref = useRef(null)

	useEffect(() => {
		let mounted = true

		if (mounted) {
			setElementWidth(ref.current.offsetWidth - 40)
		}

		return () => {
			mounted = false
		}
	}, [])


	return <> <div className='slider-container'><div className='slider' ref={ref}> <div
		style={{
			width: `${32 + loc}px`,
			left: 0
		}}
		className='slider__button' draggable
		onDragStart={(event) => {
			var emptyImage = document.createElement('img');
			// Set the src to be a 0x0 gif
			emptyImage.src = 'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==';
			event.dataTransfer.setDragImage(emptyImage, 0, 0);
		}}
		onDrag={(event) => {
			if (event.clientX < elementWidth) {
				setLoc(event.clientX)
			} else {
				setStopped(true)
			}
		}}
		onDragEnd={(event) => {
			if (stopped) {
				onDrag()
			}
			setLoc(0)
		}}
		onTouchMove={(event) => {
			if (event.touches[0].clientX < elementWidth) {
				setLoc(event.touches[0].clientX)
			} else {
				setStopped(true)
			}
		}}
		onTouchEnd={(event) => {
			console.log(loc, elementWidth)
			if (stopped) {
				onDrag()
			}

			setLoc(0)
		}}
	> <span style={{
		fontSize: '1.3em',
		fontWeight: 'bold',
		marginLeft: `${loc}px`
	}}> > </span></div>
	</div>
		<div className='slider-text'
			style={{
				width: '50%',
				top: '0.7em',
				left: `${elementWidth / 2}px`,
				marginLeft: '-25%',
				color: 'white'
			}}>{text}</div>
	</div>
		<div style={{
			textAlign: 'center'
		}}>Paki slide po to confirm!</div>
	</>
}


export default Test