import React, { useState, useContext, useEffect } from "react";
import {
  AppBar,
  Backdrop,
  Button,
  CircularProgress,
  Container,
  CssBaseline,
  IconButton,
  Tab,
  Tabs,
  Toolbar,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import TodayTabPage from "./tabpages/TodayTabPage";
import TomTabPage from "./tabpages/TomTabPage";
import SchedTabPage from "./tabpages/SchedTabPage";
import {
  OrdersLoading,
  OrdersTodayState,
  TriggerOrderRefresh,
  UserShop,
} from "../../../context/OrderPageContext/OrderPageContext";
import TopBar from "../TopBar/TopBar";
import "animate.css";
import { OrderBadgeState } from "../../../context/HomePageContext/HomePageContext";
import { SelectedTheme } from "../../../context/ThemeContext/ThemeContext";
import { ThemeProvider } from "@emotion/react";
import useLoggedUser from "../../../custom-hooks/useLoggedUser";

import useOngoingOrderDetailDialog from "./dialogs/useOngoingOrderDetailDialog";
import { getOrder } from "../../../api/public/order";
import { navigate } from "gatsby";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const OrderPage = () => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const { userShop } = useLoggedUser();

  const [shop, setUserShop] = useContext(UserShop);

  const [ordersToday, setordersToday] = useContext(OrdersTodayState);

  const [refresh, setrefresh] = useContext(TriggerOrderRefresh);

  const [badgeCount, setbadgeCount] = useContext(OrderBadgeState);

  const [themeName, themeValues, toggleDarkTheme] = useContext(SelectedTheme);

  const [isLoading, setIsLoading] = useContext(OrdersLoading);

  let newOrders = [];
  let acceptedOrders = [];
  let preparingOrders = [];
  let forpickupOrders = [];

  for (let index = 0; index < ordersToday.length; index++) {
    if (ordersToday[index].status == "Not processed") {
      newOrders.push(ordersToday[index]);
    } else if (ordersToday[index].status == "Accepted") {
      acceptedOrders.push(ordersToday[index]);
    } else if (ordersToday[index].status == "Preparing") {
      preparingOrders.push(ordersToday[index]);
    } else if (ordersToday[index].status == "For-pickup") {
      forpickupOrders.push(ordersToday[index]);
    }
  }

  setbadgeCount(
    newOrders.length +
      acceptedOrders.length +
      preparingOrders.length +
      forpickupOrders.length
  );

  useEffect(() => {
    setUserShop(userShop);
  }, []);

  useEffect(() => {
    let mounted = true;
    //open dialog here

    if (mounted) {
      window.showOrderDetail = function (orderId) {
        setrefresh(!refresh);
        navigate(`/order/details/${orderId}`);
        // getOrder(orderId).then((response) => {
        //   if (response.status === 200) {
        //     response.json().then((result) => {
        //       let order = result[0];
        //       console.log(order);
        //       showOngoingOrderDetail(order)
        //     });
        //   }
        // });
      };
    }

    return () => {
      mounted = false;
    };
  }, []);

  useEffect(() => {
    setrefresh(!refresh);
  }, []);

  return (
    <ThemeProvider theme={themeValues}>
      <CssBaseline />
      <Container sx={{ padding: 0 }}>
        <TopBar title="Order" subtitle="Get planned and engaged" />
        <Box sx={{ width: "100%", padding: 0 }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider", padding: 0 }}>
            <Tabs
              sx={{
                padding: 0,
              }}
              value={value}
              onChange={handleChange}
              variant="fullWidth"
              aria-label="basic tabs example"
            >
              <Tab label="TODAY" {...a11yProps(0)} />
              <Tab label="TOMMOROW" {...a11yProps(1)} />
              <Tab label="SCHEDULED" {...a11yProps(2)} />
            </Tabs>
          </Box>
          <TodayTabPage
            value={value}
            index={0}
            newOrders={newOrders}
            acceptedOrders={acceptedOrders}
            preparingOrders={preparingOrders}
            forpickupOrders={forpickupOrders}
          />
          <TomTabPage value={value} index={1} />
          <SchedTabPage value={value} index={2} />
        </Box>
        <Backdrop open={isLoading}>
          <CircularProgress color="primary" />
        </Backdrop>
      </Container>
    </ThemeProvider>
  );
};

export default OrderPage;
