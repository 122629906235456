import React, { useState, useContext } from "react";

import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
  TextField,
} from "@mui/material";
import { declineOrder } from "../../../../api/public/order";
import {
  TriggerOrderRefresh,
  OrdersLoading,
} from "../../../../context/OrderPageContext/OrderPageContext";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function useDeclineOrderDialog() {
  const [open, setOpen] = useState(false);

  const [order, setorder] = useState({});

  // const [refresh, setrefresh] = useContext(TriggerOrderRefresh);

  // const [isLoading, setIsLoading] = useContext(OrdersLoading);

  const showDeclineDialog = (order) => {
    setOpen(true);
    setorder(order);
  };

  const closeDeclineDialog = () => {
    setOpen(false);
  };

  const handleSubmit = async (message) => {
    try {
      // setIsLoading(true);
      await declineOrder(order?._id, message);

      closeDeclineDialog();
    } catch (error) {
      throw error;
    }
  };

  const DeclineDialog = ({ additionalActions = () => {} }) => {
    const [reason, setreason] = useState("");
    const [alertState, setAlertState] = useState({
      open: false,
      message: "This is an error alert — check it out!",
      status: "success",
    });
    return (
      <Dialog open={open} onClose={closeDeclineDialog}>
        <DialogTitle>Decline order?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            You are about to decline an order. This action can't be undone
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            name="reason"
            label="Reason to decline?"
            required
            multiline
            rows={4}
            fullWidth
            onChange={(event) => setreason(event.target.value)}
            variant="outlined"
          />
        </DialogContent>
        {alertState.open ? (
          <Alert severity={alertState.status}>{alertState.message}</Alert>
        ) : (
          <div />
        )}
        <DialogActions>
          <Button
            onClick={async () => {
              try {
                if (reason.length > 0) {
                  await handleSubmit(reason);
                  additionalActions();
                } else {
                  setAlertState((prevState) => ({
                    ...prevState,
                    open: true,
                    message: "Please provide a reason before you continue!",
                    status: "error",
                  }));
                }
              } catch (error) {
                setAlertState((prevState) => ({
                  ...prevState,
                  open: true,
                  message: `${error}`,
                  status: "error",
                }));
              }
            }}
          >
            Continue
          </Button>
          <Button onClick={closeDeclineDialog}>back</Button>
        </DialogActions>
      </Dialog>
    );
  };

  return [showDeclineDialog, closeDeclineDialog, DeclineDialog];
}
