import React from "react";
import OrderCard from "../components/OrderCard";
import "../../../../styles/css/HorizaontalCardList.css";
import useOngoingOrderDetailDialog from "../dialogs/useOngoingOrderDetailDialog";
import { navigate } from "gatsby";

const OrderList = ({ orders }) => {
  // const [
  //   showOngoingOrderDetail,
  //   closeOngoingOrderDetail,
  //   OngoingOrderDetailDialog,
  // ] = useOngoingOrderDetailDialog();

  return (
    <>
      <div className="horizontallist scrollbar-hidden">
        {orders.map((order) => {
          return (
            <OrderCard
              order={order}
              onClick={() => {
                navigate(`/order/details/${order._id}`);
              }}
            />
          );
        })}
      </div>
      {/* <OngoingOrderDetailDialog /> */}
    </>
  );
};

export default OrderList;
