import * as React from "react";
import { Router } from "@reach/router";
import HomePage from "../components/home";
import LoginPage from "../components/login";
import SignUpPage from "../components/signup";
import ProductDetails from "../components/productdetails/ProductDetails";
import MainContext from "../context/MainContext";
import PrivateRoute from "../components/privateRoute";
import FlutterRoute from "../components/flutterRoute";
import OnboardingPage from "../components/onboarding";

import "../styles/css/fonts.css";
import OrderDetailsPage from "../components/home/OrderPage/OrderDetailsPage";

// markup
const IndexPage = () => {
  return (
    <MainContext>
      <Router>
        <OnboardingPage path="/" />
        <PrivateRoute path="/app" component={HomePage} />
        <FlutterRoute path="/app/:userId/:shopId/:token" component={HomePage} />
        <LoginPage path="/login" />
        <SignUpPage path="/signup" />
        <ProductDetails path="/product/details" />
        <OrderDetailsPage path="/order/details/:orderId" />
      </Router>
    </MainContext>
  );
};

export default IndexPage;
